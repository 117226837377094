
import { Component, Vue } from 'vue-property-decorator';
import { readMyOneApp } from '@/store/apps/getters';
import { dispatchGetMyApps } from '@/store/apps/actions';

@Component
export default class Apps extends Vue {
  private tab = 0;

  private mounted() {
    this.update();
  }

  private beforeRouteUpdate(to, from, next) {
    next();
    this.update();
  }

  private update() {
    this.tab = 0;
    dispatchGetMyApps(this.$store);
  }

  private get app() {
    return readMyOneApp(this.$store)(
      Number(this.$router.currentRoute.params.id),
    );
  }
}
